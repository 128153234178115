import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import rCoin from "../assets/images/rcoin.png";

const LiveHistory = () => {
  const { liveHistory,videoValidDays,audioValidDays } = useSelector((state) => state.liveRoomHistory);
  const history = useHistory();
  const handleOpenHistory = (data) => {
    history.push("/host/historyInfo", { state: data?.date });
  };

  console.log("liveHistory" , liveHistory)

  return (
    <>
    <div className="m-auto text-center mt-2  fs-6 text-info">
      <p>Audio valid:  {audioValidDays} days &nbsp; &nbsp;Video valid:  {videoValidDays} days</p>
    </div>
      {liveHistory?.length > 0 ? (
        <>
          {liveHistory.map((data) => {
            return (
              <>
                <div
                  className="mt-4"
                  style={{ border: "1px solid #372143", borderRadius: "14px" }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2"
                    style={{
                      background: "#372143",
                      borderBottom: "1px solid #372143",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      borderRadius: "14px 14px 0px 0px",
                    }}
                  >
                    <div>
                      <h3
                        className=" mt-1"
                        style={{
                          fontWeight: 500,
                          color: "#FF1C60",
                          fontSize: "18px",
                        }}
                      >
                        Date :{moment(data?.date).format("DD-MM")}
                      </h3>
                    </div>
                  </div>
                  <div
                    style={{
                      background: "#241330",
                      borderRadius: "0px 0px 14px 14px",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center px-3 py-2"
                      style={{
                        background: "#241330",
                        borderBottom: "1px solid #372143",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                      }}
                    >
                      <div>
                        <h3
                          className="mt-1 text-color"
                          style={{ fontWeight: 400, fontSize: "16px" }}
                        >
                          Duration
                        </h3>
                      </div>

                      <div>
                        <p
                          className=" mt-1"
                          style={{
                            color: data?.audioValid ? "green" : "#FF1C60",
                            fontSize: "16px",
                          }}
             
                        >
                         Audio : - &nbsp; 

                          { data?.audioData?.duration ? data?.audioData?.duration  + " ( Min )" : 0}
                        </p>
                        <p
                          className=" mt-1"
                          style={{
                            fontSize: "16px",
                            color: data?.videoValid ? "green" : "#FF1C60",
                          }}
                        >
                         Video : - &nbsp; 

                          {data?.videoData?.duration ? data?.videoData?.duration + " ( Min )" : 0 }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-center my-4">
            <span>No data found.</span>
          </div>
        </>
      )}
    </>
  );
};

export default LiveHistory;
