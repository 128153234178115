import axios from "axios";
import { CLAIM_REWARD, GET_HOST_LIVE_HISTORY, GET_TASK, GET_TASK_HISTORY } from "./types";
import { Toast } from "../../util/Toast";

export const getTask = (id) => (dispatch) => {
  axios
    .get(`task/getTask?hostId=${id}`)
    .then((res) => {
      dispatch({
        type: GET_TASK,
        payload: res.data.data,
      });
    })
    .catch((error) => console.log("error", error));
};

export const getTaskHistory = (hostId, month) => (dispatch) => {
  axios
    .get(`task/taskRewardHistory?hostId=${hostId}&month=${month}`)
    .then((res) => {
      
      dispatch({
        type: GET_TASK_HISTORY,
        payload: res.data.data,
      });
    })
    .catch((error) => console.log("error", error));
};


export const getHostLiveHistory = (hostId) => (dispatch) => {
  axios
    .get(`hostLiveHistory/hostLiveHistoryToday?hostId=${hostId}`)
    .then((res) => {
      dispatch({
        type: GET_HOST_LIVE_HISTORY,
        payload: res.data.data,
      });
    })
    .catch((error) => console.log("error", error));
};

export const claimReward = (hostId, id) => (dispatch) => {
  axios
    .patch(`task/claimTaskReward?hostId=${hostId}&taskId=${id}`)
    .then((res) => {
      debugger
      if (res.data.status === true) {
        setTimeout(() => {

          Toast("success", `${res.data.message}`);
        },2000)
        window.location.reload(); 
      }else {
        Toast("error", `${res.data.message}`);
      }
    })
    .catch((error) => {
      Toast("error", `${error.message}`);
      console.log("error", error)
    });
};
