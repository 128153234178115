import axios from "axios";
import * as ActionType from "./history.type";

// get admin history

export const getAdminHistory = (id, start, limit, date) => (dispatch) => {
  axios
    .get(
      `weekHistory/getAgencyHistory?hostId=${id}&start=${start}&limit=${limit}&month=${date}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_HISTORY,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};
export const getAdminCashOut = (id, start, limit, date, type) => (dispatch) => {
  axios
    .get(
      `agencyRedeem/getAgencyWise?hostId=${id}&start=${start}&limit=${limit}&month=${date}&type=${type}`
    )
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_CASHOUT,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};

export const getHosthistory = (month, hostId) => (dispatch) => {
  axios
    .get(`host/topCreators?month=${month}&hostId=${hostId}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_HOST_HISTORY,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};

export const getHostEarning = (id, date) => (dispatch) => {
  axios
    .get(`hostSettlement/hostSettlementForHost?hostId=${id}&month=${date}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_ADMIN_HISTORY,
        payload: { history: res.data.history },
      });
    })
    .catch((error) => console.log("error", error));
};



export const getHosthistoryInfo = (date, hostId) => (dispatch) => {
  axios
    .get(`host/hostHistoryByDate?date=${date}&hostId=${hostId}`)
    .then((res) => {
      dispatch({
        type: ActionType.GET_HOST_HISTORY_INFO,
        payload: { history: res.data.data, total: res.data.total },
      });
    })
    .catch((error) => console.log("error", error));
};

export const getHostLiveData = (id, month) => (dispatch) => {
  axios
    .get(
      `hostLiveHistory/host/liveHistory?month=${month}&hostId=${id}`
    )
    .then((res) => {
      if (res.status) {
        debugger
        dispatch({ type: ActionType.GET_HOST_LIVE_DATA, payload: res.data });
      }
    }).catch((error) => console.log('error', error))
};
