import { getHostLiveHistory } from "./action";
import { GET_HOST_LIVE_HISTORY, GET_TASK, GET_TASK_HISTORY } from "./types";

const initialState = {
  getTaskData: [],
  getTaskHistoryData : [],
  getHostLiveHistoryData: null
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASK:
      return {
        ...state,
        getTaskData: action.payload,
      };

    case GET_HOST_LIVE_HISTORY:

      return {
        ...state,
        getHostLiveHistoryData: action.payload,
      };

    case GET_TASK_HISTORY:
      
      return {
        ...state,
        getTaskHistoryData: action.payload,
      };
    default:
      return state;
  }
};

export default taskReducer;