import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { claimReward, getHostLiveHistory, getTask } from "../store/task/action";
import CoinIcon from "../assets/images/CoinIcon";
import LeftIcon from "../assets/images/LeftIcon";
import TaskHistory from "./TaskHistory";

const GetTask = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hostId = localStorage.getItem("hostId");
  const { getTaskData, getHostLiveHistoryData } = useSelector(
    (state) => state.task
  );

  console.log("getHostLiveHistoryData" , getHostLiveHistoryData)

  const [activeTab, setActiveTab] = useState("task");

  // const getHostLiveHistoryData = [
  //   {
  //     audioDuration: 50,
  //     videoDuration: 70,
  //     todayEarning: 10000,
  //   },
  // ];

  const countdownRef = useRef(0);
  const [countdownDisplay, setCountdownDisplay] = useState("");

  useEffect(() => {
    dispatch(getTask(hostId));
    dispatch(getHostLiveHistory(hostId));
  }, [hostId]);

  useEffect(() => {
    const now = new Date();
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0);
    countdownRef.current = Math.floor((midnight - now) / 1000);

    const interval = setInterval(() => {
      if (countdownRef.current > 0) {
        countdownRef.current -= 1;
        setCountdownDisplay(formatTime(countdownRef.current));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}h ${minutes}m ${secs}s`;
  };

  const handleTaskClick = (id) => {
    dispatch(claimReward(hostId, id));
  };

  return (
    <>
      <div className="page-container">
        <div className="page-content">
          <div className="main-wrapper p-0">
            <div className="main-section">
              <div
                className="row p-3 mb-2 justify-content-center"
                style={{
                  zIndex: "9",
                  position: "fixed",
                  width: "100%",
                  top: "0",
                  background: "rgb(35, 28, 44)",
                }}
              >
                <div className="col-4 d-flex justify-content-start align-items-center">
                  <LeftIcon onClick={() => history.goBack()} />
                </div>
                <div className="col-4 d-flex justify-content-center text-nowrap align-items-center">
                  <h3 className="fw-bold mb-0">Task Center</h3>
                </div>
                <div className="col-4"></div>
              </div>
              <div className="mb-4 px-3" style={{ marginTop: "65px" }}>
                <div>
                  <div className="d-flex justify-content-center gap-5 align-items-center pt-2 text-xl fw-bold">
                    <span
                      onClick={() => setActiveTab("task")}
                      style={{
                        borderBottom:
                          activeTab === "task" ? "2px solid white" : "none",
                      }}
                    >
                      <h5>Today Data</h5>
                    </span>
                    <span
                      onClick={() => setActiveTab("history")}
                      style={{
                        borderBottom:
                          activeTab === "history" ? "2px solid white" : "none",
                      }}
                    >
                      <h5>History</h5>
                    </span>
                  </div>
                  {activeTab === "task" && (
                    <div
                      className="w-100 mx-auto mt-3 rounded"
                      style={{ backgroundColor: "#372143" }}
                    >
                      <table className="table rounded text-center text-dark">
                        <thead
                          style={{
                            background:
                              "linear-gradient(90deg, rgb(134, 36, 219), rgb(255, 0, 92))",
                          }}
                        >
                          <tr className="rounded">
                            <th className="fw-bold">Audio Time</th>
                            <th className="fw-bold">Video Time</th>
                            <th className="fw-bold">Earning</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-truncate">
                              {getHostLiveHistoryData?.[0]?.audioDuration || 0}
                            </td>
                            <td className="text-truncate">
                              {getHostLiveHistoryData?.[0]?.videoDuration || 0}
                            </td>
                            <td className="d-flex align-items-center justify-content-center text-truncate">
                              {getHostLiveHistoryData?.[0]?.todayEarning || 0}{" "}
                              <CoinIcon />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  {activeTab === "history" && <TaskHistory />}
                </div>
              </div>

              {
                activeTab === "task" &&
                <>
                <div className="text-center">
                <h5>Daily Tasks</h5>
                <p>Time left until midnight: {countdownDisplay}</p>
              </div>

              <div className="task-container">
                {getTaskData.map((task, index) => {
                  const audioDurationData =
                    task?.type === "audio"
                      ? getHostLiveHistoryData?.[0]?.audioDuration
                      : getHostLiveHistoryData?.[0]?.videoDuration;
                  return (
                    <>
                    <div
                      className="task-card shadow-sm pt-4 px-4 pb-3 mt-4 position-relative mx-auto"
                      style={{
                        borderRadius: "12px",
                        maxWidth: "300px",
                        maxHeight: "300px",
                        backgroundColor: "#372143",
                      }}
                      key={index}
                    >
                      <div
                        className="position-absolute top-0 end-0 text-dark px-2 py-1 rounded-bottom-left"
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          borderTopRightRadius: "10px",
                          borderEndStartRadius: "10px",
                          background:
                            "linear-gradient(90deg, rgb(134, 36, 219), rgb(255, 0, 92))",
                        }}
                      >
                        Reward {task.coinsRewarded}
                      </div>
                      <h2 className="fw-bold text-dark text-center">
                        {task.type.toUpperCase()}
                      </h2>
                      <div
                        className="mt-3"
                        style={{ fontSize: "13px", color: "#c5c5c5" }}
                      >
                        <p className="mb-0">
                          Required Coins :{" "}
                          <span className="fw-bold text-white">
                            {" "}
                            {task.coinRequired}{" "}
                          </span>
                        </p>
                        <p className="mt-1">
                          Remaining Coins :{" "}
                          <span className="fw-bold text-white">
                            {task.coinRequired -
                              getHostLiveHistoryData?.[0]?.todayEarning >
                            0
                              ? task.coinRequired -
                                getHostLiveHistoryData?.[0]?.todayEarning
                              : "Achieved"}
                          </span>
                        </p>
                        <p className="mb-1">
                          Time Required :{" "}
                          <span className="fw-bold text-white">
                            {" "}
                            {task?.timeRequired} mins{" "}
                          </span>
                        </p>
                        <p className="mt-1">
                          Remaining Time :{" "}
                          <span className="fw-bold text-white">
                            {task?.timeRequired - audioDurationData > 0
                              ? task?.timeRequired - audioDurationData + " Mins" :
                              getHostLiveHistoryData?.length === 0 ? "Remaining"
                              : "Achieved"}{" "}
                          </span>
                        </p>
                      </div>
                      {(() => {
                        const AudiOrVideo =
                          task?.type === "audio"
                            ? getHostLiveHistoryData?.[0]?.audioDuration
                            : getHostLiveHistoryData?.[0]?.videoDuration;

                        let disable;
                        if (
                          AudiOrVideo >= task?.timeRequired &&
                          getHostLiveHistoryData?.[0]?.todayEarning >=
                            task?.coinRequired &&
                          !task?.isClaimed
                        ) {
                          disable = false;
                        } else {
                          disable = true;
                        }

                        // Return JSX directly here with conditional opacity
                        return (
                          <button
                            className="btn btn-primary mt-3 w-100"
                            onClick={() => handleTaskClick(task?._id)}
                            disabled={disable}
                            style={{
                              opacity: disable ? 0.5 : 1,
                              background: disable
                                ? "transparent"
                                : "linear-gradient(90deg, rgb(134, 36, 219), rgb(255, 0, 92))",
                              border: disable ? "" : "none",
                            }}
                          >
                            {task?.isClaimed
                              ? " Already Claimed"
                              : "Claim Coin"}
                          </button>
                        );
                      })()}
                    </div>
                    
                   </>
                  );
                })}
              </div>
              </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetTask;
